<template>
	<div id="login">
		<el-form v-if="$store.state.logType=='登录'" :model="loginData" :rules="rules" ref="loginData"
			label-position="top">
			<el-form-item label="手机号" prop="phoneNumber" v-if="loginType == 'code'">
				<el-input v-model="loginData.phoneNumber" placeholder="请输入手机号" :max="11"> </el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode" v-if="loginType == 'code'">
				<el-input v-model="loginData.verifyCode" placeholder="请输入验证码" :max="6">
					<el-button slot="append" @click="sendCodeLoginFun(loginData.phoneNumber)" :disabled="disabled">
						{{textCode}}
					</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="用户名" prop="userName" v-if="loginType == 'pwd'">
				<el-input v-model="loginData.userName" placeholder="请输入用户名">
				</el-input>
			</el-form-item>
			<el-form-item label="密码" prop="passWord" v-if="loginType == 'pwd'">
				<el-input v-model="loginData.passWord" placeholder="请输入密码" show-password></el-input>
			</el-form-item>
			<el-form-item class="options">
				<p>
					<el-link @click="changeLoginType('code')" :underline="false"
						v-if="loginType == 'pwd'">使用手机验证码登录</el-link>
					<el-link @click="changeLoginType('pwd')" :underline="false"
						v-if="loginType == 'code'">使用密码登录</el-link>
					<el-link type="primary" @click="$store.state.logType = '忘记密码'" :underline="false">忘记密码</el-link>
				</p>
				<el-button class="submit" @click="login('loginData','登录')">登录</el-button>
			</el-form-item>
		</el-form>
		<el-form v-if="$store.state.logType=='注册'" :model="loginData" :rules="rules" ref="loginData"
			label-position="top">
			<el-form-item label="手机号(用户名)" prop="phoneNumber">
				<el-input v-model="loginData.phoneNumber" placeholder="请输入手机号" :max="11"> </el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode">
				<el-input v-model="loginData.verifyCode" placeholder="请输入验证码" :max="6">
					<el-button slot="append" @click="sendCodeFun(loginData.phoneNumber)" :disabled="disabled">
						{{textCode}}
					</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="密码" prop="passWord">
				<el-input v-model="loginData.passWord" placeholder="请输入密码" show-password></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="passWord2">
				<el-input v-model="loginData.passWord2" placeholder="请再次输入密码" show-password></el-input>
			</el-form-item>
			<el-form-item class="options">
				<el-button class="submit" @click="registerNow('loginData','注册')">立即注册</el-button>
			</el-form-item>
		</el-form>
		<el-form v-if="$store.state.logType=='忘记密码'" :model="loginData" :rules="rules" ref="loginData"
			label-position="top">
			<!-- <el-form-item label="用户名">
				<el-input v-model="loginData.userName" placeholder="请输入用户名" :max="11">
				</el-input>
			</el-form-item> -->
			<el-form-item label="手机号" prop="phoneNumber">
				<el-input v-model="loginData.phoneNumber" placeholder="请输入手机号" :max="11">
					<el-button slot="append" @click="sendCodeChangePasswordFun(loginData.phoneNumber)"
						:disabled="disabled">
						{{textCode}}
					</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode">
				<el-input v-model="loginData.verifyCode" placeholder="请输入验证码"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="passWord">
				<el-input v-model="loginData.passWord" placeholder="请输入密码" show-password></el-input>
			</el-form-item>
			<el-form-item label="确认新密码" prop="passWord2">
				<el-input v-model="loginData.passWord2" placeholder="请再次输入密码" show-password></el-input>
			</el-form-item>
			<el-form-item class="options">
				<p><el-link type="primary" @click="$store.state.logType = '登录'" :underline="false">返回登录</el-link></p>
				<el-button class="submit" @click="changePassWordFun('loginData')">确认修改</el-button>
			</el-form-item>
		</el-form>


	</div>
</template>

<script>
	import {
		changePassword, // 修改密码
		sendCode, // 获取注册验证码
		sendCodeLogin, // 获取登录验证码
		sendCodeChangePassword, // 获取验证码
		login, // 登录
		regist,
		getUserByPhoneNumber
	} from '@/request/api.js';
	export default {
		name: 'login',
		data() {
			return {
				oldUser: '',
				olduserShow: false,
				loginType: 'pwd', // 登录类型 pwd:手机号密码登录 code：手机号验证码登录
				loginData: {
					userName: '',
					phoneNumber: "", // 手机号
					passWord: "", // 密码
					passWord2: "", // 确认密码
					phoneNumber: '',
					verifyCode: ''
				},
				miaoCode: 60,
				textCode: '获取验证码',
				disabled: false,
				rules: {
					userName: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					passWord: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					phoneNumber: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					passWord2: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}]
				}
			}
		},
		created() {},
		mounted() {},
		watch: {
			"$store.state.logType": {
				handler(val) {
					this.logType = val;
					this.resetForm('loginData')
				}
			}
		},
		methods: {
			// 切换登录状态
			changeLoginType(type) {
				this.loginType = type;
				this.loginData.passWord = '';
				this.loginData.verifyCode = '';
				this.$refs["loginData"].resetFields();
			},

			// 修改密码
			changePassWordFun(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.loginData)
						if (this.loginData.passWord != this.loginData.passWord2) {
							this.$notify({
								title: '提示',
								message: `两次密码输入不一致`,
								type: 'error',
								position: 'bottom-right'
							});
						} else {
							changePassword(this.loginData)
								.then(res => {
									if (res.code == 200) {
										console.log(res)
										this.$emit('loginSuccess', {
											type: true,
										})

										this.$notify({
											title: `修改成功`,
											message: `修改成功，请重新登录`,
											type: 'success',
											position: 'bottom-right'
										});
										// this.resetForm('loginData');
									} else {
										this.$notify({
											title: '提示',
											message: `${res.msg}`,
											type: 'error',
											position: 'bottom-right'
										});
									}
								}).catch(err => {
									console.log(err)
								})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},


			// 去注册
			toRegister() {
				this.$emit('toRegister', {
					type: 'success',
					register: false
				})
			},

			// 获取手机验证码

			sendCodeFun(code) {
				if (!code) {
					this.$notify({
						title: '提示',
						message: `请输入手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!(/^1[3-9]\d{9}$/.test(code))) {
					this.$notify({
						title: '提示',
						message: `请输入正确的手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					this.disabled = true;
					this.countdown(this.miaoCode);
					sendCode(code).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '验证码发送成功',
								message: `验证码发送成功，请及时查看。60秒内请勿重复获取。`,
								type: 'success',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '验证码发送失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			sendCodeLoginFun(code) {
				if (!code) {
					this.$notify({
						title: '提示',
						message: `请输入手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!(/^1[3-9]\d{9}$/.test(code))) {
					this.$notify({
						title: '提示',
						message: `请输入正确的手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					this.disabled = true;
					this.countdown(this.miaoCode);
					sendCodeLogin(code).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '验证码发送成功',
								message: `验证码发送成功，请及时查看。60秒内请勿重复获取。`,
								type: 'success',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '验证码发送失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			sendCodeChangePasswordFun(code) {
				if (!code) {
					this.$notify({
						title: '提示',
						message: `请输入手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!(/^1[3-9]\d{9}$/.test(code))) {
					this.$notify({
						title: '提示',
						message: `请输入正确的手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					this.disabled = true;
					this.countdown(this.miaoCode);
					sendCodeChangePassword(code).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '验证码发送成功',
								message: `验证码发送成功，请及时查看。60秒内请勿重复获取。`,
								type: 'success',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '验证码发送失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},

			// 倒计时
			countdown(miaoCode) {
				this.textCode = `${miaoCode}秒后重新获取`;
				setTimeout(() => {
					if (miaoCode > 0) {
						this.miaoCode--;
						this.textCode = `${miaoCode}秒后重新获取`;
						this.countdown(this.miaoCode)
					} else {
						this.disabled = false;
						this.textCode = '获取验证码';
						this.miaoCode = 60;
					}
				}, 1000)
			},

			login(formName, type) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {};
						if (this.loginType == 'pwd') {
							data.userName = this.loginData.userName;
							data.password = this.loginData.passWord;
						} else if (this.loginType == 'code') {
							data.phoneNumber = this.loginData.phoneNumber
							data.verifyCode = this.loginData.verifyCode;
						};

						// console.log(data)
						login(data).then(res => {
							if (res.code == 200) {
								this.$emit('loginSuccess', {
									type: true,
								})
								localStorage.setItem(
									'headToken', res.data
								)
								this.$notify({
									title: `${type}成功`,
									message: `${type}成功`,
									type: 'success',
									position: 'bottom-right'
								});
								this.resetForm('loginData');
							} else {
								this.$notify({
									title: `${type}失败`,
									message: `${res.msg}`,
									type: 'error',
									position: 'bottom-right'
								});
							}
						}).catch(err => {
							console.log(err)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			registerNow(formName, type) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							phoneNumber: this.loginData.phoneNumber,
							verifyCode: this.loginData.verifyCode,
							password: this.loginData.passWord,
							userName: this.loginData.phoneNumber
						}

						getUserByPhoneNumber(data).then(res => {
							if (res.code == 200) {
								if (res.data) {
									this.$emit('loginSuccess', {
										type: true,
										oldUser: res.data,
										registeData: data
									})
								} else {
									regist(data).then(res => {
										if (res.code == 200) {
											this.$emit('loginSuccess', {
												type: true,
											})
											localStorage.setItem(
												'headToken', res.data
											)
											this.$notify({
												title: `${type}成功`,
												message: `${type}成功`,
												type: 'success',
												position: 'bottom-right'
											});
											this.resetForm('loginData');
										} else {
											this.$notify({
												title: `${type}失败`,
												message: `${res.msg}`,
												type: 'error',
												position: 'bottom-right'
											});
										}
									}).catch(err => {
										console.log(err)
									})
								}

							}
						})



					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss" scoped>
	#login {
		// margin: 60px 0 30px;

		::v-deep .el-form {
			margin: 50px 50px 0;

			.el-form-item {
				// display: flex;
				// align-items: center;

				.el-form-item__label {
					font-size: 20px;
					color: rgba(47, 52, 71, 1);
					width: 200px;
				}

				.el-form-item__content {
					height: 80px;
					flex: 1;
					margin: 0;
					display: flex;

					* {
						font-size: 16px;
					}

					.el-input {
						flex: 1;
						height: 100%;

						.el-input__inner {
							height: 100%;
						}

						.el-input-group__append {
							button {
								height: 100%;
							}
						}
					}

					.el-select {
						width: 100%;
					}

					.el-form-item__error {
						font-size: 14px;
						color: #F56C6C;
					}
				}
			}

			.options .el-form-item__content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: auto;

				.el-button {
					width: 100%;
					height: 80px;
				}

				.submit {
					background-color: #28B0A6;

					* {
						color: #fff;
					}
				}

				p {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 20px;
				}

				.toRegister {
					background-color: #fef2e0;
					padding: 10px 50px;
					border-radius: 10px;
					font-size: 14px;
					display: flex;
					align-items: center;

					a {
						color: #518dda;
						display: flex;
						align-items: center;
						font-size: 14px;
						margin-left: 5px;

						img {
							display: block;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
</style>