<template>
	<div id="editUser">
		<el-form :model="editUserData" :rules="rules" ref="editUserData" label-position="left">
			<h1>基础个人信息</h1>
			<el-form-item label="投稿人姓名" prop="fullName">
				<el-input placeholder="请输入投稿人姓名" clearable v-model="editUserData.fullName"></el-input>
			</el-form-item>
			<el-form-item label="所属机构" prop="hospital">
				<el-select v-model="editUserData.hospital" filterable remote placeholder="请选择所属机构(可关键词搜索)" clearable
					:remote-method="getHospitalList" :loading="loading" @change="getHospitalList()" allow-create
					default-first-option>
					<el-option label="其他机构" value="other"></el-option>
					<el-option v-for="(item,i) in options" :key="i" :label="item.hospitalName"
						:value="item.hospitalName">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属科室" prop="department">
				<el-input placeholder="请输入所在科室" v-model="editUserData.department" clearable></el-input>
			</el-form-item>
			<el-form-item label="手机号" prop="phoneNumber">
				<el-input v-model="editUserData.phoneNumber" placeholder="请输入手机号" :max="11" :disabled="false"
					clearable></el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode" v-if="oldPhone != editUserData.phoneNumber">
				<el-input v-model="editUserData.verifyCode" clearable placeholder="请输入验证码">
					<el-button slot="append" @click="sendCodeFun(editUserData.phoneNumber)" :disabled="disabled">
						{{textCode}}
					</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="所在地区" prop="location">
				<el-select v-model="editUserData.location" filterable remote placeholder="请选择所属地区(可关键词搜索)" clearable
					:loading="loading">
					<el-option v-for="(item,i) in districtList" :key="item.id" :label="item.location" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<h1>信息备案 <span v-if="otherList[0]" @click="openOther()">查看历史备案信息</span></h1>
			<el-form-item label="劳务合同" prop="cardId">
				<!-- :on-remove="Remove1"  :on-preview="handlePictureCardPreview"-->
				<el-upload class="upload" :action="`${baseUrl}/article/document/uploadFile`" list-type="picture-card"
					multiple :limit="limit1" :on-exceed="Exceed1" :file-list="file1" :before-upload="beforeUpload1"
					:on-success="upSuccess1" :before-remove="beforeRemove"
					:disabled="userInfo.certificatesStatus=='SUCCESS'">
					<el-button v-if="file1.length<limit1 && userInfo.certificatesStatus!='SUCCESS'" size="small"
						type="primary"> 点击上传 </el-button>
					<div slot="file" slot-scope="{file}">
						<i class="el-icon-s-management"
							v-if="file.url.includes('pdf') || file.name.includes('pdf')"></i>
						<img class="el-upload-list__item-thumbnail" v-else :src="file.url" alt="">
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span v-if="userInfo.certificatesStatus!='SUCCESS'" class="el-upload-list__item-delete"
								@click="Remove1(file , file1)">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
			</el-form-item>
			<el-form-item label="身份证(人像面)" prop="cardId">
				<el-upload class="upload" :action="`${baseUrl}/article/document/uploadIdCard`" list-type="picture-card"
					:on-preview="handlePictureCardPreview" :on-remove="Remove2" multiple :limit="limit2"
					:on-exceed="Exceed2" :file-list="file2" :before-upload="beforeUpload2" :on-success="upSuccess2"
					:disabled="userInfo.certificatesStatus=='SUCCESS'">
					<el-button v-if="file2.length<limit2&& userInfo.certificatesStatus!='SUCCESS'" size="small"
						type="primary">点击上传</el-button>
					<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
			</el-form-item>
			<el-form-item label="银行卡(卡号面)" prop="cardId">
				<el-upload class="upload" :action="`${baseUrl}/article/document/uploadBankCard`"
					list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="Remove3" multiple
					:limit="limit3" :on-exceed="Exceed3" :file-list="file3" :before-upload="beforeUpload3"
					:on-success="upSuccess3" :disabled="userInfo.certificatesStatus=='SUCCESS'">
					<el-button v-if="file3.length<limit3&& userInfo.certificatesStatus!='SUCCESS'" size="small"
						type="primary">点击上传</el-button>
					<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
			</el-form-item>
			<el-form-item label="医师资格证明" prop="cardId">
				<el-upload class="upload" :action="`${baseUrl}/article/document/uploadFile`" list-type="picture-card"
					:on-preview="handlePictureCardPreview" :on-remove="Remove4" multiple :limit="limit4"
					:on-exceed="Exceed4" :file-list="file4" :before-upload="beforeUpload4" :on-success="upSuccess4"
					:disabled="userInfo.certificatesStatus=='SUCCESS'">
					<el-button v-if="file4.length<limit4&& userInfo.certificatesStatus!='SUCCESS'" size="small"
						type="primary">点击上传</el-button>
					<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
			</el-form-item>
			<el-form-item label="身份证号" prop="cardId">
				<el-input placeholder="上传材料自动带入" v-model="editUserData.cardId" :disabled="false" clearable
					:max="18"></el-input>
			</el-form-item>
			<el-form-item label="银行卡号" prop="bankNumber">
				<el-input placeholder="上传材料自动带入" v-model="editUserData.bankNumber" :disabled="false" clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="开户行" prop="bankName">
				<el-input placeholder="上传材料自动带入" v-model="editUserData.bankName" :disabled="false" clearable></el-input>
			</el-form-item>
			<el-form-item class="options">
				<!-- <el-button class="reset" @click="resetForm('editUserData')">重置</el-button> -->
				<el-button class="submit" @click="submitForm('editUserData')">提交</el-button>
			</el-form-item>
		</el-form>

		<el-dialog :visible.sync="dialogVisible" v-if="dialogVisible" :modal-append-to-body="true"
			:append-to-body="true">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>

	</div>
</template>

<script>
	// let district = {
	// 	"4区": ["四川", "湖南", "海南", "广西", "广东", "云南", "贵州"],
	// 	"3区": ["河南", "辽宁", "甘青宁", "新疆", "大连", "陕西", "吉林", "黑龙江"],
	// 	"2区": ["济南", "青岛", "河北", "山西", "天津", "潍坊", "北京", "内蒙古", "烟台"],
	// 	"1区": ["江苏", "湖北", "江西", "上海", "安徽", "苏北", "浙江一区", "浙江二区"]
	// };
	import baseUrl from "@/request/config.js"
	import {
		getUser, // 获取用户信息
		editUser, // 注册
		sendCodeLogin, // 获取验证码
		sendCode,
		verify, // 校验验证码
		hospitalList, // 获取机构列表
		delCertificates,
		locationList,
		downloadOldData
	} from '@/request/api.js';
	export default {
		name: 'editUser',
		data() {
			return {
				baseUrl: '',
				file1: [],
				limit1: 3,
				file2: [],
				limit2: 1,
				file3: [],
				limit3: 1,
				file4: [],
				limit4: 3,
				otherList: [],
				dialogImageUrl: '',
				dialogVisible: false,
				districtList: [],
				editUserData: {
					fullName: "", // 用户姓名
					cardId: '', // 身份证号
					bankNumber: '', // 银行卡号
					bankName: '', // 开户行
					hospital: '', // 所属医院
					department: '', // 所属科室
					phoneNumber: '', // 手机号码
					certificates: [], // 文件列表
					location: '' // 所在地区
				},
				userInfo: '',
				oldPhone: '',
				miaoCode: 60,
				textCode: '获取验证码',
				disabled: false,
				rules: {
					fullName: [{
						required: true,
						message: '请输入投稿人姓名',
						trigger: 'blur'
					}, {
						min: 2,
						max: 8,
						message: '长度在 2 到 8 个字符',
						trigger: 'blur'
					}],
					cardId: [{
						required: false,
						message: '请输入身份证号',
						trigger: 'blur'
					}, {
						required: false,
						message: '请输入正确的身份证号',
						pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
						trigger: 'blur',
					}],
					hospital: [{
						required: true,
						message: '请选择所属机构',
						trigger: 'change|blur'
					}],
					department: [{
						required: true,
						message: '请输入所在科室',
						trigger: 'blur'
					}],
					phoneNumber: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						message: '请输入正确的手机号',
						pattern: /^1[3-9]\d{9}$/,
						trigger: 'blur',
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						message: '请输入正确的验证码',
						pattern: /^[A-Za-z0-9]{4,6}$/,
						trigger: 'change',
					}],
					location: [{
						required: true,
						message: '请选择所在地区',
						trigger: 'blur'
					}],
					bankNumber: [{
						required: false,
						message: '请输入银行卡号',
						trigger: 'blur'
					}],
					bankName: [{
						required: false,
						message: '请输入开户行名称',
						trigger: 'blur'
					}],
				},
				options: [{
					hospitalName: "清华大学第一附属医院北京华信医院 （原酒仙桥医院）"
				}, {
					hospitalName: "沈阳市第一人民医院"
				}],
				list: [],
				loading: false,


			}
		},
		created() {
			this.baseUrl = baseUrl;
			// this.districtEditor()
			this.getlocationList();
			this.getHospitalList();
			this.getUserFun();
		},
		mounted() {},
		methods: {
			// 查看历史备案信息
			openOther() {
				// window.open(downloadOldData())

				this.otherList.map(item => {
					window.open(item.fileName)
				})
			},

			getlocationList() {
				locationList().then(res => {
					if (res.code == 200) {
						this.districtList = res.data

						this.$forceUpdate();
					}
				})
			},
			beforeRemove(file, fileList) {
				console.log(JSON.parse(JSON.stringify(file)), JSON.parse(JSON.stringify(fileList)));
				// console.log(JSON.parse(JSON.stringify(this.file1)));
				// return this.$confirm(`确定移除 ${ file.name }？`);
			},

			Remove1(file, fileList) {
				console.log(JSON.parse(JSON.stringify(file)));
				if (file.id) {
					delCertificates({
						id: file.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
						}
					})
				};
				this.editUserData.certificates.map((item, i) => {
					if (file.response) {
						if (item.fileName == file.response.data) {
							this.editUserData.certificates.splice(i, 1)
						}
					} else {
						if (item.fileName == file.name) {
							this.editUserData.certificates.splice(i, 1)
						}
					}

				});
				this.file1.map((item, i) => {
					if (file.response) {
						console.log(1, item)
						if (item.response.data == file.response.data) {
							this.file1.splice(i, 1)
							console.log(2)
						}
					} else {
						if (item.fileName == file.fileName) {
							this.file1.splice(i, 1)
							console.log(3)
						}
					}
				})

				// this.file1 = fileList;
				this.$forceUpdate();
			},
			Preview1(file) {
				console.log(file);
			},
			Exceed1(files, fileList) {
				this.$message.warning(
					`当前限制上传 ${this.limit1} 个文件，本次上传了 ${files.length} 个文件，共上传了 ${files.length + fileList.length} 个文件`
				);
			},
			beforeUpload1(file) {
				const isJPG = file.type.includes('image');
				const isPDF = file.type.includes('pdf');
				const isLt2M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPDF) {
					this.$message.error('只能上传图片格式与PDF格式的文件!');
				}
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 5MB!');
				}
				return (isJPG || isPDF) && isLt2M;
			},
			upSuccess1(res, file, fileList) {
				// console.log(JSON.parse(JSON.stringify(res)))
				if (res.code == 200) {
					let certificates = {
						type: 'CONTRACT',
						fileName: res.data
					};
					this.editUserData.certificates = [...this.editUserData.certificates, certificates];
				};
				this.file1 = fileList;
				console.log(this.file1)
				setTimeout(() => {
					this.$forceUpdate();
				}, 100);
			},
			Remove2(file, fileList) {
				console.log(file, fileList);
				if (file.id) {
					delCertificates({
						id: file.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
						}
					})
				};
				this.editUserData.certificates.map((item, i) => {
					if (file.response) {
						if (item.fileName == file.response.data) {
							this.editUserData.certificates.splice(i, 1)
						}
					} else {
						if (item.fileName == file.name) {
							this.editUserData.certificates.splice(i, 1)
						}
					}
				})
				this.file2 = fileList;
				this.$forceUpdate();
			},
			Preview2(file) {
				console.log(file);
			},
			Exceed2(files, fileList) {
				this.$message.warning(
					`当前限制上传 ${this.limit2} 个文件，本次上传了 ${files.length} 个文件，共上传了 ${files.length + fileList.length} 个文件`
				);
			},
			beforeUpload2(file) {
				const isJPG = file.type.includes('image');
				const isLt2M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('只能上传图片格式的文件!');
				}
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			upSuccess2(res, file, fileList) {
				if (res.code == 200) {
					if (res.data.cardId) {
						this.editUserData.cardId = res.data.cardId;
						this.$message({
							message: '识别成功，信息已自动录入。',
							type: 'success'
						});
					} else {
						this.$alert('照片识别未成功，请上传清晰度更高的图片或手动输入。', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
							center: true
						});
					}
					let certificates = {
						type: 'CARD',
						fileName: res.data.fileName
					}
					this.editUserData.certificates = [...this.editUserData.certificates, certificates];
					this.file2 = fileList;
				}
				setTimeout(() => {
					this.$forceUpdate();
				}, 100);
			},
			Remove3(file, fileList) {
				// console.log(file, fileList);
				if (file.id) {
					delCertificates({
						id: file.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
						}
					})
				};
				this.editUserData.certificates.map((item, i) => {
					if (file.response) {
						if (item.fileName == file.response.data) {
							this.editUserData.certificates.splice(i, 1)
						}
					} else {
						if (item.fileName == file.name) {
							this.editUserData.certificates.splice(i, 1)
						}
					}
				})
				this.file3 = fileList;
				this.$forceUpdate();
			},
			Preview3(file) {
				console.log(file);
			},
			Exceed3(files, fileList) {
				this.$message.warning(
					`当前限制上传 ${this.limit3} 个文件，本次上传了 ${files.length} 个文件，共上传了 ${files.length + fileList.length} 个文件`
				);
			},
			beforeUpload3(file) {
				const isJPG = file.type.includes('image');
				const isLt2M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('只能上传图片格式的文件!');
				}
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			upSuccess3(res, file, fileList) {
				if (res.code == 200) {
					if (res.data.bankNumber) {
						this.editUserData.bankNumber = res.data.bankNumber;
						this.editUserData.bankName = res.data.bankName;
						this.$message({
							message: '识别成功，信息已自动录入。',
							type: 'success'
						});
					} else {
						this.$alert('照片识别未成功，请上传清晰度更高的图片或手动输入。', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
							center: true
						});
					}
					let certificates = {
						type: 'BANKCARD',
						fileName: res.data.fileName
					}
					this.editUserData.certificates = [...this.editUserData.certificates, certificates];
					this.file3 = fileList;
				}
				setTimeout(() => {
					this.$forceUpdate();
				}, 100);
			},
			Remove4(file, fileList) {
				// console.log(file, fileList);
				if (file.id) {
					delCertificates({
						id: file.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
						}
					})
				};
				this.editUserData.certificates.map((item, i) => {
					if (file.response) {
						if (item.fileName == file.response.data) {
							this.editUserData.certificates.splice(i, 1)
						}
					} else {
						if (item.fileName == file.name) {
							this.editUserData.certificates.splice(i, 1)
						}
					}
				})
				this.file4 = fileList;
				this.$forceUpdate();
			},
			Preview4(file) {
				console.log(file);
			},
			Exceed4(files, fileList) {
				this.$message.warning(
					`当前限制上传 ${this.limit4} 个文件，本次上传了 ${files.length} 个文件，共上传了 ${files.length + fileList.length} 个文件`
				);
			},
			beforeUpload4(file) {
				const isJPG = file.type.includes('image');
				const isLt2M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('只能上传图片格式的文件!');
				}
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			upSuccess4(res, file, fileList) {
				if (res.code == 200) {
					let certificates = {
						type: 'CERTIFICATE',
						fileName: res.data
					}
					this.editUserData.certificates = [...this.editUserData.certificates, certificates];
					this.file4 = fileList;
				}
				setTimeout(() => {
					this.$forceUpdate();
				}, 100);
			},
			districtEditor() {
				let arr = [];
				for (let k in district) {
					arr = [...arr, ...district[k]]
				};
				this.districtList = arr;
			},
			// 获取用户信息
			getUserFun() {
				getUser().then(res => {
					if (res.code == 200) {
						let data = JSON.parse(JSON.stringify(res.data));
						data.location = data.location - 0;
						this.oldPhone = data.phoneNumber;
						// console.log(JSON.parse(JSON.stringify(data)))
						this.userInfo = data;
						this.editUserData.fullName = data.fullName;
						this.editUserData.cardId = data.cardId;
						this.editUserData.bankNumber = data.bankNumber;
						this.editUserData.bankName = data.bankName;
						this.editUserData.hospital = data.hospital;
						this.editUserData.department = data.department;
						this.editUserData.phoneNumber = data.phoneNumber;
						this.editUserData.certificates = data.certificates;
						this.editUserData.location = data.location;
						data.certificates.map(item => {
							item.status = 'success'
							item.name = item.fileName
							item.url = item.fileName
							if (item.type == 'CONTRACT') {
								// 劳务协议
								this.file1.push(item)
							} else if (item.type == 'CARD') {
								// 身份证
								this.file2.push(item)
							} else if (item.type == 'BANKCARD') {
								// 银行卡
								this.file3.push(item)
							} else if (item.type == 'CERTIFICATE') {
								// 医师资格证
								this.file4.push(item)
							} else {
								this.otherList.push(item)
							}
							// console.log(JSON.parse(JSON.stringify(item)))
						})

					}
					// console.log(JSON.parse(JSON.stringify(this.editUserData)))
				})
			},
			// 获取机构列表
			getHospitalList(query) {
				this.loading = true;
				let data = {};
				data.hospitalName = query;
				hospitalList(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.options = res.data.records;
					}
				})
			},

			handlePictureCardPreview(file) {
				if (file.url.includes('pdf') || file.response.data.includes('pdf')) {
					window.open(file.url)
				} else {
					this.dialogImageUrl = file.url;
					this.dialogVisible = true;
				}
			},

			// 提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid, error) => {
					if (valid) {
						if ((this.userInfo.fullName && this.userInfo.fullName != '') || (this.userInfo
								.fullName &&
								this.userInfo.fullName != '')) {
							this.editUserFun();
						} else {
							this.$confirm('姓名和身份证后一旦提交无法修改，是否提交？', '提示', {
								// this.$confirm('一旦提交后姓名无法修改，请再确认', '提示', {
								confirmButtonText: '提交',
								type: 'info'
							}).then(() => {
								this.editUserFun();
							}).catch(err => {
								console.log(err)
							})
						}
					} else {
						// console.log(error);
						for (let i in error) {
							this.$message.error(error[i][0].message);
							this.$notify({
								title: '提交失败',
								message: `${error[i][0].message}`,
								type: 'error',
								position: 'bottom-right'
							});
							return false;
						}
						return false;
					}
				});
			},

			editUserFun() {
				let data = this.editUserData;

				if (this.oldPhone == data.phoneNumber) {
					delete data.phoneNumber;
				}
				editUser(data).then(res => {
					if (res.code == 200) {
						this.$notify({
							title: '提交信息成功',
							message: `${res.msg}`,
							type: 'success',
							position: 'bottom-right'
						});
						this.$emit('editUserSuccess', {
							type: 'success',
							login: false
						})
					} else {
						this.$notify({
							title: '提交失败',
							message: `${res.msg}`,
							type: 'error',
							position: 'bottom-right'
						});
					}
				});
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			// 获取手机验证码
			sendCodeFun(code) {
				if (!code) {
					this.$notify({
						title: '提示',
						message: `请输入手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!(/^1[3-9]\d{9}$/.test(code))) {
					this.$notify({
						title: '提示',
						message: `请输入正确的手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					this.disabled = true;
					this.countdown(this.miaoCode);
					sendCode(code).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '验证码发送成功',
								message: `验证码发送成功，请及时查看。60秒内请勿重复获取。`,
								type: 'success',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '验证码发送失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},

			// 倒计时
			countdown(miaoCode) {
				this.textCode = `${miaoCode}秒后重新获取`;
				setTimeout(() => {
					if (miaoCode > 0) {
						this.miaoCode--;
						this.textCode = `${miaoCode}秒后重新获取`;
						this.countdown(this.miaoCode)
					} else {
						this.disabled = false;
						this.textCode = '获取验证码';
						this.miaoCode = 60;
					}
				}, 1000)
			},
		}
	}
</script>

<style lang="scss" scoped>
	#editUser {
		// margin: 60px 0 30px;

		::v-deep .el-form {
			margin: 50px 50px 0;

			h1 {
				font-size: 30px;
				font-weight: bold;
				color: #D43030;
				margin: 40px 0;

				span {
					font-size: 14px;
					color: #ccc;
					cursor: pointer;
				}
			}

			.upload {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				.el-upload-list {
					display: flex;
					flex-direction: row;
					// width: 610px;

					.el-upload-list__item {
						margin-bottom: 0;
						width: 90px;
						height: 90px;
						display: flex;
						justify-content: center;
						align-items: center;

						.el-icon-s-management {
							font-size: 40px;
							color: #ccc;
						}
					}
				}

				.el-upload {
					background: none;
					border: none;
					width: 120px;
					height: 90px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					// line-height: 1;
				}
			}

			.el-form-item {
				display: flex;
				align-items: center;

				.el-form-item__label {
					font-size: 20px;
					color: rgba(47, 52, 71, 1);
					width: 150px;
				}

				.el-form-item__content {
					min-height: 80px;
					flex: 1;
					margin: 0;
					display: flex;

					* {
						font-size: 16px;
					}

					.el-input {
						flex: 1;
						height: 80px;
						// width: 300px;

						.el-input__inner {
							height: 100%;
						}

						.el-input-group__append {
							width: 140px;


							.el-button {
								width: 140px;
								height: 100%;
								text-align: center;
							}
						}


					}

					.el-select {
						width: 100%;

					}

					.el-form-item__error {
						font-size: 14px;
						color: #F56C6C;
					}
				}
			}

			.options .el-form-item__content {
				display: flex;
				align-items: center;
				justify-content: center;

				.el-button {
					width: 180px;
					height: 80px;
				}

				.submit {
					background-color: #28B0A6;

					* {
						color: #fff;
					}
				}
			}
		}
	}
</style>